<template>
    <div>
        <ButtonCta :buttonConfig="getButtonConfig" />
    </div>
</template>

<script>
    import { UpsellPlansCTAConfig } from 'aa/vue/FormConfig';
    import ButtonCta from 'atoms/ButtonCta';
    import CheckoutOptions from 'aa/vue/models/CheckoutOptions';
    import { mapGetters, mapState } from 'vuex';
    import Plan from 'aa/vue/models/Plan';
    import { tracking } from 'services/Tracking';
    import {
        getRowHeaderTitle,
        getRowHeaderPosition,
        getRowHeaderNumberTotal,
    } from 'aa/components/AAUpsellTracking';
    import { COUPON_TYPES } from 'aa/vue/models/CouponDiscount';

    export default {
        name: 'PlansButtonListState',
        props: {
            /**
             * @property Plan
             */
            plan: {
                required: true,
            },
            plans: {
                required: true,
            },
        },
        data: function () {
            return {
                papConfig: UpsellPlansCTAConfig,
                buttonConfig: {},
            };
        },
        computed: {
            ...mapGetters('flow', {
                flowType: 'type',
            }),
            ...mapState({
                promo: (state) => state.promo,
            }),
            ...mapState('user', ['isExSubscriber']),
            getButtonConfig() {
                this.buttonConfig = Object.assign({}, this.papConfig.button);
                this.buttonConfig.displayText = this.getCtaText();
                this.buttonConfig.event = this.handleSelectedPlan;
                return this.buttonConfig;
            },
        },
        components: {
            ButtonCta,
        },
        methods: {
            getCtaText() {
                if (this.showNoFreeTrialCopy()) {
                    return this.$getLocale('get_started');
                } else {
                    return this.getFreeTrialCopy();
                }
            },
            getFreeTrialCopy() {
                return (
                    this.$getLocale('try') +
                    ' ' +
                    (this.isFreeTrialWithEFTCoupon()
                        ? this.plan.couponString
                        : this.plan.getTrialCopyWithoutCoupon())
                );
            },
            showNoFreeTrialCopy() {
                return (
                    !this.plan.isFreeTrial ||
                    (this.isExSubscriber && !this.isFreeTrialWithEFTCoupon())
                );
            },
            isFreeTrialWithEFTCoupon() {
                return (
                    this.plan.hasValidCoupon() && this.plan.couponType === COUPON_TYPES.FREE_TRIAL
                );
            },
            handleSelectedPlan(event) {
                this.trackSelectPlanBtn(event.target);

                // save selected plan on local storage
                CheckoutOptions.get().save({
                    selectedPlan: this.plan,
                    flowType: this.flowType,
                });

                // redirect to next step
                let redirect = '/account/user-flow/' + this.flowType;
                if (this.promo) {
                    redirect += '/?promo=' + this.promo;
                }
                window.location.href = redirect;
            },
            trackSelectPlanBtn(btnElement) {
                this.$store.dispatch('plan/updateSelectedPlan', this.plan);

                let trackingParams = {
                    pickPlanSelection: '1',
                    ctaText: this.getCtaText(),
                    rowHeaderTitle: getRowHeaderTitle(btnElement),
                    rowHeaderPosition: getRowHeaderPosition(btnElement),
                    rowHeaderNumberTotal: getRowHeaderNumberTotal(),
                };

                localStorage.setItem(
                    'promoOffer',
                    this.$store.getters['plan/getPromoOfferStringForTracking'],
                );

                Object.assign(trackingParams, this.$store.getters['getProductParamsForTracking']);
                tracking.trackStateFired = true;
                tracking.trackAction('trackPickPlanSelection', trackingParams);
            },
        },
    };
</script>
