<!-- START TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy -->
<template>
    <div>
        <div
            :class="billingCadenceOverlayClass"
            id="billing-cadence-modal-overlay"
            @click="handleCloseCadenceModalCta"></div>

        <div :class="billingCadenceModalClass" id="billing-cadence-modal">
            <div class="icon closeIconWhite" @click="handleCloseCadenceModalCta"></div>
            <div class="cadence-image"></div>

            <div class="cadence-modal-content">
                <h2>GO ANNUAL & SAVE</h2>
                <div class="byline">Switch to annual billing and save 23%</div>

                <radiobox-elm
                    id="billing-cadence-monthly"
                    name="billing-cadence"
                    v-model="billingCadencePicked"
                    :value="getProductMonthly">
                    <div class="radio-text">
                        <div class="radio-heading">Pay monthly</div>
                        <div>${{ currentPlanRawPrice }}/month, after free trial</div>
                    </div>
                </radiobox-elm>
                <radiobox-elm
                    id="billing-cadence-annually"
                    name="billing-cadence"
                    v-model="billingCadencePicked"
                    :value="getProductAnnual">
                    <div class="radio-text">
                        <div class="radio-heading">
                            Pay annually
                            <PillComponent
                                className="save-pill"
                                textValue="SAVE 23%"></PillComponent>
                        </div>
                        <div>
                            {{ currentAnnualPlanMonthlyPrice }} (${{ currentAnnualPlanRawPrice }}
                            billed annually), after free trial
                        </div>
                    </div>
                </radiobox-elm>

                <ButtonCta :buttonConfig="billingCadenceCtaConfig"></ButtonCta>
            </div>
        </div>
    </div>
</template>

<script>
    import PillComponent from 'aa/vue/components/PillComponent';
    import RadioboxElm from 'atoms/RadioboxElm';
    import ButtonCta from 'atoms/ButtonCta';
    import { ACTION_NAMES, tracking } from 'services/Tracking';
    import Product from 'aa/vue/models/Product';
    import { mapActions, mapGetters, mapState } from 'vuex';

    // START TEST PLOCTOPLUS-2610: pap free trial cta ab test
    import papFreeTrialCtaAbTest from 'aa/vue/abTestRelated/PapFreeTrialCtaAbTestMixin.js';
    // END TEST PLOCTOPLUS-2610: pap free trial cta ab test

    export default {
        name: 'BillingCadenceModal',
        mixins: [
            // START TEST PLOCTOPLUS-2610: pap free trial cta ab test
            papFreeTrialCtaAbTest,
            // END TEST PLOCTOPLUS-2610: pap free trial cta ab test
        ],
        components: {
            PillComponent,
            RadioboxElm,
            ButtonCta,
        },
        data: function () {
            return {
                isBillingCadenceModal: this.$store.getters['variant/isBillingCadenceModal'],
                billingCadencePicked: Product.TYPE_MONTHLY,
            };
        },
        computed: {
            ...mapGetters({
                cmsImageBaseURL: 'cmsImageBaseURL',
                currentPlan: 'plan/getCurrentPlan',
                currentAnnualPlan: 'plan/getCurrentAnnualPlan',
                billingCadenceModalOpen: 'plan/getBillingCadenceModalOpen',
            }),

            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),
            currentPlanRawPrice() {
                return this.currentPlan ? this.currentPlan.rawPrice : '';
            },
            currentAnnualPlanRawPrice() {
                return this.currentAnnualPlan ? this.currentAnnualPlan.rawPrice : '';
            },
            currentAnnualPlanMonthlyPrice() {
                return this.currentAnnualPlan
                    ? this.currentAnnualPlan.annualPlanMonthlyPriceWithUnit()
                    : '';
            },
            getProductMonthly() {
                return Product.TYPE_MONTHLY.toLowerCase();
            },

            getProductAnnual() {
                return Product.TYPE_ANNUAL.toLowerCase();
            },
            billingCadenceModalClass() {
                let classList;

                classList = {
                    'billing-cadence-modal': true,
                    open: this.billingCadenceModalOpen,
                };

                return classList;
            },
            billingCadenceOverlayClass() {
                let classList;

                classList = {
                    'billing-cadence-modal-overlay': true,
                    open: this.billingCadenceModalOpen,
                };

                return classList;
            },
            billingCadenceCtaConfig() {
                return {
                    styleClass: 'cadence-cta',
                    displayText: this.isPapFreeTrialCtaAbTest
                        ? 'CONTINUE WITH FREE TRIAL'
                        : this.pageAttributes.aa_pap_button_text.toUpperCase(),
                    event: this.handleClickCadenceModalCta,
                };
            },
        },
        methods: {
            ...mapActions('plan', ['filterPlans']),
            moveBillingCadenceModalToBody() {
                const billingCadenceOverlay = document.getElementById(
                    'billing-cadence-modal-overlay',
                );
                const billingCadenceModal = document.getElementById('billing-cadence-modal');
                const bodyElement = document.body;

                bodyElement.appendChild(billingCadenceOverlay);
                bodyElement.appendChild(billingCadenceModal);
            },
            handleCloseCadenceModalCta(includeTracking = true) {
                if (includeTracking) {
                    tracking.trackAction(ACTION_NAMES.TRACK_PICK_PLAN_MODAL_ABANDON);
                }
                this.$store.commit('plan/setBillingCadenceModalOpen', false);
            },
            handleClickCadenceModalCta() {
                if (this.billingCadencePicked === Product.TYPE_ANNUAL) {
                    this.$store.dispatch('plan/updateSelectedPlan', this.currentAnnualPlan);
                }
                this.handleCloseCadenceModalCta(false);
                const trackingToggleParams = {
                    pickPlanSku: this.currentAnnualPlan.recurlyCode,
                    pickPlanSelection: '1',
                    purchaseProductName: this.currentAnnualPlan.planTitle,
                    pickPlanIsAnnual:
                        this.billingCadencePicked === Product.TYPE_ANNUAL ? 'true' : 'false',
                };
                tracking.trackAction(
                    ACTION_NAMES.TRACK_PICK_PLAN_MODAL_SELECTION,
                    trackingToggleParams,
                );
                this.$emit('handleSubmit');
            },
        },
        mounted() {
            if (this.isBillingCadenceModal) {
                this.moveBillingCadenceModalToBody();

                this.filterPlans({
                    planType: Product.TYPE_MONTHLY,
                });
            }
        },
    };
</script>
<!-- END TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy -->
