<template>
    <div class="plans-app">
        <div v-if="upsellPlansAnnualEnabled">
            <ToggleButton
                :leftCopy="$getLocale(planTypeMonthly)"
                :rightCopy="$getLocale(planTypeAnnual)"
                toggleClass="toggle-button-wrapper"
                @toggle="handleToggle"></ToggleButton>
            <div
                class="sub-header-annual-upsell"
                v-html="$getLocale('save_with_an_annual_plan')"></div>
        </div>
        <UpsellPlansHeaderList :plans="plans" :toggled="toggled" />
        <ValuePropList :plans="plans" />
        <PlansButtonList :plans="plans" />
    </div>
</template>

<script>
    import PickAPlan from 'aa/vue/components/PickAPlan';
    import UpsellPlansHeaderList from 'aa/vue/components/upsell/UpsellPlansHeaderList';
    import PlansButtonList from 'aa/vue/components/upsell/PlansButtonList.vue';
    import ValuePropList from 'aa/vue/components/upsell/ValuePropList.vue';
    import ToggleButton from 'atoms/ToggleButton';
    import { mapGetters, mapActions } from 'vuex';
    import Product from 'aa/vue/models/Product';

    export default {
        name: 'UpsellPlans',
        extends: PickAPlan,
        components: {
            ValuePropList,
            PlansButtonList,
            UpsellPlansHeaderList,
            ToggleButton,
        },
        data: function () {
            return {
                toggled: 0,
            };
        },
        computed: {
            ...mapGetters({
                upsellPlansAnnualEnabled: 'featureFlags/upsellPlansAnnualEnabled',
            }),
            planTypeMonthly() {
                return Product.TYPE_MONTHLY;
            },
            planTypeAnnual() {
                return Product.TYPE_ANNUAL;
            },
        },
        methods: {
            ...mapActions('plan', ['filterPlans']),
            handleToggle(action) {
                this.toggled = new Date().getTime();
                const selectedPlanType = action ? this.planTypeAnnual : this.planTypeMonthly;
                this.filterPlans({
                    planType: selectedPlanType,
                });

                om.trackAction('trackPickPlanToggle', {
                    ctaText: selectedPlanType,
                    pickPlanIsAnnual: action.toString(),
                });
            },
        },
    };
</script>
