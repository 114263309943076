import { mapGetters } from 'vuex';
import { getLocale } from 'services/LocalizationService';
import priceMixin from 'aa/vue/plugins/priceMixin';
import { pluralizeDuration } from 'aa/helpers/stringManipulation';
import { FeatureKeys } from 'aa/helpers/featureConstants';
import Product from 'aa/vue/models/Product';

export default {
    mixins: [priceMixin],
    computed: {
        recurly() {
            return this.$store.state.payment.recurly;
        },
        ...mapGetters(['featureIsActive']),
    },
    data() {
        return {
            pricing: null,
            coupon: null,
        };
    },
    methods: {
        getCoupon: function (plan, coupon) {
            let isCampaignServiceEnabled = this.featureIsActive(
                FeatureKeys.CAMPAIGN_SERVICE_ENABLED,
            );

            if (isCampaignServiceEnabled) {
                return plan.offerCouponCode;
            }

            return coupon;
        },

        // replaces the trial copy for the plans if a coupon is associated with the user flow
        updatePriceAndCouponCopy(plan, promo) {
            if (typeof this.recurly === 'object') {
                const coupon = this.getCoupon(plan, promo);
                try {
                    const payload = {
                        plan: plan,
                        coupon: coupon,
                    };

                    this.$store.dispatch('plan/updatePricingInfo', payload);
                } catch (e) {}
            }
        },
        stripCountryCodeFromPromo(promoCode) {
            const split = promoCode.split('-');
            if (split.length >= 2 && split[split.length - 1].length === 2) {
                this.coupon.regionCode = split.pop().toLowerCase();
            }
        },
        setupRecurlyPricing(successFunction, errorFunction, overridePricing = true) {
            if (typeof this.recurly === 'object') {
                if (overridePricing === true) {
                    this.pricing = this.recurly.Pricing();
                }

                this.pricing.on('set.coupon', (coupon) => {
                    const { code } = coupon;
                    this.coupon = coupon;

                    // Get region from coupon code
                    if (code) {
                        this.stripCountryCodeFromPromo(code);
                    }

                    successFunction(this.coupon);
                });

                this.pricing.on('error.coupon', (error) => errorFunction(this.couponError(error)));
            }
        },
        applyCoupon(planCode, couponCode) {
            if (this.pricing && planCode && couponCode) {
                this.pricing.plan(planCode).coupon(couponCode);
            }
        },
        checkForCouponErrors(coupon, countryCode, freeTrialBlocked = true) {
            let containsError = false;
            if (coupon.regionCode != null && coupon.regionCode.toUpperCase() !== countryCode) {
                containsError = 'd';
            } else if (coupon.discount.type == 'free_trial' && freeTrialBlocked) {
                containsError = 'a';
            } else if (coupon.discount.type == 'dollars') {
                if (
                    coupon.discount.amount[this.pricing.price.currency.code] == 0 ||
                    coupon.discount.amount[this.pricing.price.currency.code] == null
                ) {
                    containsError = 'e';
                }
            } else if (coupon.redemption_resource !== 'subscription') {
                containsError = true;
            }
            return containsError;
        },

        /*
    When we see a coupon with the language - "single_use" as the duration we need to check the plan
    to see if we're dealing with a monthly or annual plan.
    if annual plan - return localized "first year"
    if monthly plan - return localized "first month"

    dependency on ability to check plan;
    */
        singleUseTerm(planType) {
            const tags = {
                monthly: 'first_month',
                annual: 'first_year',
            };
            return getLocale(tags[planType]) || '';
        },

        currentSubscriptionCouponObjTransformer(
            currentSubscriptionCouponObj,
            currency = { symbol: '$', code: 'USD', subunits: 100 },
        ) {
            const {
                discountType,
                discountPercent,
                balanceInCents,
                duration,
                temporalUnit,
                temporalAmount,
                freeTrialUnit,
                freeTrialAmount,
            } = currentSubscriptionCouponObj;

            const adjustedAmount = balanceInCents
                ? this.transformDiscountInCents(balanceInCents, currency)
                : balanceInCents;

            const mapCouponObj = {
                discount: {
                    type: discountType,
                    rate: discountPercent / 100 || null,
                    trial: {
                        unit: freeTrialUnit,
                        amount: freeTrialAmount,
                    },
                    amount: adjustedAmount,
                },
                duration: duration,
                temporal_unit: temporalUnit,
                temporal_amount: temporalAmount,
            };

            return mapCouponObj;
        },

        transformDiscountInCents(discountInCents, currency) {
            let adjustedAmount = {};
            const index = `${currency.code}`;
            adjustedAmount[currency.code] = discountInCents[index] / currency.subunits;
            return adjustedAmount;
        },

        discountString(couponObj, currencySymbol, currencyCode) {
            let discount = '';
            if (
                couponObj['discount'] &&
                couponObj['discount']['type'] &&
                couponObj['discount']['type'] == 'dollars'
            ) {
                // Fixed Amount coupon
                discount = couponObj['discount']['amount'][currencyCode];

                discount = this.pricePrettyPrint(
                    discount,
                    currencySymbol,
                    currencyCode,
                    CBS.Registry.region.locale,
                );
            } else {
                discount = `${couponObj.discount.rate * 100}%`;
            }
            return discount;
        },

        /** Takes Recurly couponObj */
        formatCouponString(
            couponObj,
            currencySymbol,
            currencyCode,
            planType = Product.TYPE_MONTHLY,
        ) {
            let couponString = '',
                amount = 0,
                duration = '';

            if (
                !couponObj['temporal_unit'] &&
                !couponObj['temporal_amount'] &&
                couponObj['discount']['type'] == 'free_trial'
            ) {
                amount = parseInt(couponObj['discount']['trial']['amount']);
                duration = couponObj['discount']['trial']['unit'];
                duration = pluralizeDuration(amount, duration);
                return `${amount} ${getLocale(duration)} ${getLocale('free')}`;
            }

            // fixed price and % based coupons
            else {
                let discount = this.discountString(couponObj, currencySymbol, currencyCode);

                if (couponObj['temporal_unit']) {
                    amount = parseInt(couponObj['temporal_amount']);
                }
                if (
                    couponObj['duration'] &&
                    (couponObj['duration'] === 'forever' || couponObj['duration'] === 'single_use')
                ) {
                    duration = couponObj['duration'];
                } else {
                    duration = couponObj['temporal_unit'] ? couponObj['temporal_unit'] : '';
                }
                duration = pluralizeDuration(amount, duration);
                if (
                    couponObj['temporal_unit'] &&
                    !couponObj['temporal_unit'].includes('year') &&
                    planType === Product.TYPE_ANNUAL
                ) {
                    duration = 'year';
                    amount = this.getUnitAmount(couponObj);
                }

                if (amount > 0) {
                    if (planType === Product.TYPE_ANNUAL && amount > 1) {
                        duration = 'years';
                    }
                    couponString = getLocale('percent_discount_over_time', {
                        percent: discount,
                        time:
                            amount > 0
                                ? `${amount} ${getLocale(duration)}`
                                : `${getLocale(duration)}`,
                    });
                } else {
                    if (duration === 'forever') {
                        couponString = `${discount} ${getLocale('off')}`;
                    } else if (duration === 'single_use') {
                        couponString = getLocale('percent_discount_first_term', {
                            percent: discount,
                            first_term: this.singleUseTerm(planType),
                        });
                    }
                }

                return couponString;
            }
        },
        getUnitAmount(coupon) {
            let unitAmount = coupon['temporal_amount'];
            if (coupon['temporal_unit'] === 'month') {
                let remainder = unitAmount % 12 !== 0 ? 1 : 0;
                unitAmount = Math.floor(unitAmount / 12);
                unitAmount = unitAmount + remainder;
            }
            return unitAmount;
        },

        couponError(res) {
            let errorString = '';

            switch (res) {
                case 'a': // Free trial not available to existing subscribers
                    errorString =
                        'recurly_this_promotional_code_is_valid_for_new_subscriptions_only';
                    break;
                case 'b': // This coupon has expired // this is currently uncatchable will fallback to default
                    errorString = 'recurly_this_promotional_code_has_expired';
                    break;
                case 'c': // This one use coupon has already been redeemed
                    errorString = 'recurly_this_promotional_code_has_already_been_redeemed';
                    break;
                case 'd': // This coupon is not available in your region
                    errorString = 'recurly_this_promotional_code_is_not_supported_in_your_country';
                    break;
                case 'e': // This coupon is not available in your region for currency regions
                    errorString =
                        'recurly_this_promotional_code_is_not_in_the_same_currency_as_your_subscription';
                    break;
                default:
                    //  error all other issues
                    errorString = 'recurly_this_promotional_code_is_not_valid';
            }
            return errorString;
        },
    },
};
