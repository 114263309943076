<template id="SignIn">
    <section class="padded-container">
        <div class="aa-layout sign-in-grid" :aa-region="formConfig.aaRegion">
            <div class="grid-item">
                <h3 class="steps-header" v-if="displayHeading">
                    {{ pageAttributes.aa_steps_header }}
                </h3>
                <p v-if="subHeading" class="sub-heading">{{ subHeading }}</p>
                <p v-if="formMessage" class="form-message">{{ formMessage }}</p>
                <p
                    v-if="formMessageError"
                    class="form-message error"
                    role="alert"
                    aria-live="polite">
                    {{ formMessageError }}
                </p>
                <BannerEl
                    v-if="this.isPartner"
                    :classList="['form-banner']"
                    :copy="this.skyUserPrompt"></BannerEl>
            </div>

            <form id="sign-in-form" v-on:submit.stop.prevent="handleSubmit" novalidate>
                <div class="form-wrapper">
                    <div
                        v-for="(value, key, index) in formConfig.inputList"
                        :key="key"
                        class="grid-item cbs-text-input span-cols">
                        <LabelInput
                            :elementIndex="index"
                            :inputConfig="value"
                            v-model="form[key]"
                            :class="{ error: isInError(key) }"
                            @keyup="localHandleKeyUp"
                            @showHidePasswordStatus="handleShowHidePasswordStatus" />
                        <div v-for="error in errors" :key="key">
                            <div
                                :id="key + '_error_msg'"
                                :class="textInputErrorCSS(key)"
                                v-if="error[key]"
                                aria-live="polite"
                                role="alert">
                                {{ error[key] }}
                            </div>
                        </div>
                    </div>

                    <UserAcknowledgement></UserAcknowledgement>
                    <div class="grid-item text-center span-cols">
                        <ButtonCta
                            :buttonConfig="buttonConfig"
                            :class="{ disabled: disableFormSubmit }"
                            :aria-disabled="disableFormSubmit" />
                    </div>

                    <InputElm
                        v-for="(value, key) in formConfig.hiddenInputList"
                        :inputConfig="value"
                        v-bind:key="formConfig.hiddenInputList.ref"
                        v-model="form[key]" />
                    <input type="submit" style="display: none" />
                </div>
            </form>

            <div v-if="displayForgotPassLink" class="grid-item text-center">
                <a
                    v-if="forgotPassCallback"
                    @click="useForgotPassCallback"
                    tabindex="0"
                    role="button"
                    class="form-link qt-forgotpass vue-focusable">
                    <span @click="forgotPassRouteTrack">
                        {{ $getLocale('forgot_password') }}
                    </span>
                </a>
                <router-link
                    v-else
                    to="/account/forgotpassword/"
                    tabindex="0"
                    role="link"
                    class="form-link qt-forgotpass vue-focusable">
                    <span @click="forgotPassRouteTrack">
                        {{ $getLocale('forgot_password') }}
                    </span>
                </router-link>
            </div>

            <div v-if="displayMVPDConnectCTA">
                <slot name="belowForm">
                    <SectionRule :dividerCopy="$getLocale('or')"></SectionRule>
                    <div class="grid-item text-center">
                        <span v-if="!isInternational" class="mvpd-partner-signin-copy">
                            {{ getProviderCopy() }}
                        </span>
                        <div class="live-tv-button qt-livetvbtn">
                            <router-link :to="{ name: 'MVPD' }" tabindex="0" class="live-tv-button">
                                <span class="live-tv-button-copy" @click="liveTvRouteTrack">
                                    {{ getCtaDisplayTextCopy() }}
                                </span>
                            </router-link>
                        </div>
                    </div>
                </slot>
            </div>
            <template v-if="displaySignUpNonNative">
                <div class="grid-item text-center span-cols non-native-signup-grid">
                    <span class="not-subscriber-copy">{{ notSubscriberSignUpCopy }}</span>
                    <ButtonCta :buttonConfig="buttonConfigNonNativeSignUpCta" />
                </div>
            </template>
            <div v-if="isInternational">
                <BackToRendezvousOptions :separator="false"></BackToRendezvousOptions>
            </div>
        </div>
    </section>
</template>

<script>
    import BackToRendezvousOptions from 'aa/vue/components/BackToRendezvousOptions';
    import BannerEl from 'atoms/BannerEl';
    import ButtonCta from 'atoms/ButtonCta';
    import Domain from 'helpers/Domain';
    import InputElm from 'atoms/InputElm';
    import LabelInput from 'atoms/LabelInput';
    import MVPDAuthService from 'aa/vue/services/MVPDAuthService';
    import MVPDBindMixin from 'aa/vue/plugins/MVPDBindMixin';
    import NotificationBar from 'aa/vue/components/NotificationBar';
    import PartnerMixin from './partner/PartnerMixin';

    // START TEST PLOCTOPLUS-1298: google captcha
    import captchaMixin from 'aa/vue/plugins/captchaMixin';
    // END TEST PLOCTOPLUS-1298: google captcha

    // CBSCOMSUB-4696
    import NotificationBarModel from 'aa/vue/models/NotificationBarModel';
    import PartnerService from 'services/PartnerService'; // ~/src/web-allaccess/src/js/vue/services/PartnerService.js
    import SectionRule from 'atoms/SectionRule';
    import StepsHeader from 'aa/vue/components/StepsHeader';
    import commonMixins from 'aa/vue/plugins/account/commonMixins';
    import isEqual from 'lodash/isEqual';
    import {
        AA_XHR_BASE_PATH,
        GIFT_PURCHASE_ADD_URL,
        ACCOUNT_URL,
        SIGNUP_URL,
    } from 'aa/vue/constants/aaRoutes';
    import { unbindWithAuthSuite, deauthorize } from 'services/ApiService';
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { AA_ADDITIONAL_LOGIN, AA_PURCHASE, MVPD, RENDEZVOUS } from 'helpers/featureConstants';
    import { AUTHSUITE_ENABLED, WEB_SIGN_IN_MVPD_ENABLED } from 'aa/helpers/featureConstants';
    import { ACTION_NAMES, tracking } from 'services/Tracking';
    import { APP_LOG_TYPE } from 'services/TrackingService';
    import { FLOW_TYPE } from 'models/FlowModel';
    import { USER_REG_TYPE, PAGE_TYPES } from 'aa/vue/constants/aaTrackingConstants';
    import { SignInConfig, NonNativeSignUpCtaConfig } from 'aa/vue/FormConfig';
    import { getMVPDAuthZObj, parseAuthsuiteAccessToken } from 'helpers/mvpdStorage';
    import UserAcknowledgement from 'aa/vue/components/consents/UserAcknowledgement';
    import DMAService from 'services/DMAService';
    import { AUTHZ_AUTHN_ERROR, FLOWTYPE_SHO_MIGRATED } from 'aa/vue/constants/shared';
    import { REDEMPTION_CODE_ALREADY_USED_BY_ANOTHER_USER } from 'services/ApiService/constants';
    import {
        addVariantClassesToBody,
        removeVariantClassesFromBody,
    } from 'abTests/CoreXhrAbTestClass';
    import aarpMixin from 'aa/vue/plugins/aarpMixin';
    import { PARTNERS } from 'aa/vue/apps/partner/PartnerConstants';
    import { HOME_PATH } from 'aa/vue/constants/aaRoutes';

    export default {
        // CBSCOMSUB-4696
        mixins: [
            commonMixins,
            MVPDBindMixin,
            PartnerMixin,
            aarpMixin,
            // START TEST PLOCTOPLUS-1298: google captcha
            captchaMixin,
            // END TEST PLOCTOPLUS-1298: google captcha
        ],

        props: {
            errorCallBack: {
                type: Function,
            },
            successCallback: {
                type: Function,
                default: (context) => {
                    if (Domain.isInternational()) {
                        // Reset promo after succesfull user signin
                        context.$store.commit('setPromo', null);
                    }
                    context.$store.dispatch('user/setUserData').then((val) => {
                        context.__afterDispatch();
                    });
                },
            },
            hideForgotPassLink: {
                type: Boolean,
                required: false,
                default: false,
            },

            displayHeading: {
                type: Boolean,
                required: false,
                default: true,
            },

            forgotPassCallback: {
                type: Function,
            },
            trackingPayload: {
                type: Object,
                required: false,
                default: () => {
                    return {};
                },
            },
            fromConfirmation: {
                type: Boolean,
                default: () => false,
            },
            fromPartnerConfirmation: {
                type: Boolean,
                default: () => false,
            },
            prePopulatedFields: {
                type: Object,
                required: false,
            },
            backendAuthsuiteIntegration: {
                type: Boolean,
                default: () => true,
            },
        },
        data: function () {
            return {
                form: {},
                errors: [],
                buttonConfig: Object.assign(SignInConfig.buttonConfig, {
                    event: this.handleSubmit,
                }),
                buttonConfigNonNativeSignUpCta: Object.assign(
                    NonNativeSignUpCtaConfig.buttonConfig,
                    {
                        event: this.onClickSignUpNonNative,
                    },
                ),
                isDomestic: Domain.isDomestic(),
                isInternational: Domain.isInternational(),
                fbCount: 0,
                formMessage: '',
                formMessageError: '',
                userAuthSuiteAccountRefreshed: false,
                attemptedUserRefresh: false,
                passIsVisible: 0,
                // START TEST PLOCTOPLUS-1298: google captcha
                captchaAction: 'FORM_SIGN_IN',
                // END TEST PLOCTOPLUS-1298: google captcha
                formIsValid: false,
                inPartnerFlow: this.$store.getters['partner/inPartnerFlow'],
            };
        },
        methods: {
            ...mapActions('authSuite', {
                checkAccessStatus: 'checkAccessStatus',
                registerWithApi: 'registerWithApi',
                emitAuthError: 'emitAuthZError',
                refreshAuthSuiteAccess: 'refreshAuthSuiteAccess',
            }),
            ...mapActions('partner', {
                bindWithPartner: 'bindWithPartner',
            }),
            getFormData() {
                const httpData = {};

                for (let key in this.form) {
                    if (this.form.hasOwnProperty(key)) {
                        httpData[key] = this.form[key].value;
                    }
                }

                if (this.userConsent) {
                    httpData.flowType = this.flowType;
                }

                return httpData;
            },
            handleShowHidePasswordStatus(payload) {
                this.passIsVisible = Number(payload);
                tracking.trackAction(
                    this.passIsVisible
                        ? ACTION_NAMES.SHOWHIDE_PASSWORD_SHOW
                        : ACTION_NAMES.SHOWHIDE_PASSWORD_HIDE,
                    {
                        passIsVisible: this.passIsVisible.toString(),
                    },
                );
            },
            authSuiteRefreshSuccessCallBack: function () {
                this.userAuthSuiteAccountRefreshed = true;
                this.checkAccessStatus();
            },
            authSuiteTokenErrorCallBack: function (message) {
                if (message === REDEMPTION_CODE_ALREADY_USED_BY_ANOTHER_USER) {
                    this.loginEvent.emitFail({
                        errorType: AUTHZ_AUTHN_ERROR,
                        overrides: {
                            brandName: 'Paramount+',
                        },
                        heading: 'mvpd_connection_already_exists_headline',
                        subHeading: 'mvpd_connection_already_exists_subheadline',
                        primaryButton: {
                            label: 'close',
                        },
                    });
                } else {
                    this.checkAccessStatus();
                }
            },

            useForgotPassCallback: function (e) {
                this.forgotPassCallback(this, e);
            },

            /**
             * this object comes from LabelInput -> handleKeyUp()
             * @param obj
             */
            localHandleKeyUp(obj) {
                this.handleSubmit(obj.event);
            },

            handleSubmit(e) {
                e.preventDefault();
                if (e.type === 'click' && this.disableFormSubmit) {
                    return false;
                }

                this.trackFormSubmit();

                let checkForm = this.checkForm(e);

                if (checkForm) {
                    this.formIsValid = true;
                    // START TEST PLOCTOPLUS-1298: google captcha
                    const callBackArguments = [e];
                    if (!this.runFraudProtection(this.captchaAction, callBackArguments)) {
                        this.handleValidForm(e);
                    }
                    // END TEST PLOCTOPLUS-1298: google captcha
                }
                return false;
            },

            getCtaDisplayTextCopy() {
                return this.isInternational
                    ? this.$getLocale('sign_in_with_partner')
                    : this.pageAttributes.mvpd_connect_cta;
            },

            getProviderCopy() {
                return this.pageAttributes.mvpd_copy_above_connect_cta;
            },

            handleLiveTVSubmit(e) {
                this.liveTvRouteTrack();
                window.location.assign(MVPDAuthService.paths.mvpdHome);
            },

            forgotPassRouteTrack(e) {
                tracking.trackAction(ACTION_NAMES.SIGN_IN_FORGOT_PASSWORD);
            },

            liveTvRouteTrack(e) {
                tracking.trackAction(ACTION_NAMES.SIGN_IN_LIVE_TV, {
                    clickLabel: this.$getLocale('tv_provider_sign_in_button'),
                });
            },

            onClickSignUpNonNative(e) {
                let ctaText = e.target.innerText.trim();
                tracking.trackAction(ACTION_NAMES.SIGN_UP_NON_NATIVE_RENDEZVOUS, { ctaText });

                let { regionBaseUrl } = this.$root.serverData;
                // from rendezvous app
                window.location.assign(`${regionBaseUrl}${SIGNUP_URL}`);
            },

            prePopulateFields() {
                try {
                    if (
                        this.$store.getters['flow/isEdu'] &&
                        this.$store.getters['user/eduProfile']
                    ) {
                        Object.assign(SignInConfig.inputList.email, {
                            value: this.$store.getters['user/eduProfile'].email,
                        });
                    } else if (this.prePopulatedFields?.email?.value) {
                        Object.assign(SignInConfig.inputList.email, this.prePopulatedFields.email);
                    }
                } catch (e) {}
            },

            checkForm: function (e) {
                e.preventDefault();

                this.errors = [];

                if (!this.form.email || this.form.email.value === '') {
                    this.errors.push({
                        email: this.$getLocale('email_required'),
                    });
                } else if (!this.validEmail(this.form.email.value)) {
                    this.errors.push({
                        email: this.$getLocale('please_enter_a_valid_email_address'),
                    });
                }

                if (!this.form.password || this.form.password.value === '') {
                    this.errors.push({
                        password: this.$getLocale('password_required'),
                    });
                }

                //Length Rules

                //Is Sanitized

                if (this.errors.length === 0) {
                    this.formMessageError = '';
                    this.formMessage = '';
                    return true;
                } else {
                    this.buttonConfig.styleClass = this.buttonConfig.styleClass.filter(
                        (cssClass) => cssClass !== 'disabled',
                    );
                }
            },

            validEmail: (email) => {
                const re =
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },

            handleValidForm: async function () {
                if (this.buttonConfig.styleClass.indexOf('disabled') >= 0) {
                    return;
                }
                this.buttonConfig.styleClass.push('disabled');

                const context = this;

                try {
                    await this.unbindMVPDIfNeeded();
                } catch (e) {
                    return;
                }

                this.trackLogin(ACTION_NAMES.LOGIN);

                if (this.isProviderEnabled) this.checkAccessStatus();
                this.$store.$http
                    .doPost(`${AA_XHR_BASE_PATH}login/`, this.getFormData())
                    .then(async (res) => {
                        // START TEST PLOCTOPLUS-1298: google captcha
                        this.trackCaptchaTokenValidationResult(res);
                        // END TEST PLOCTOPLUS-1298: google captcha
                        if (res.success) {
                            context.formMessageError = '';
                            context.formMessage = '';

                            localStorage.setItem('userProfileNotSelectedYet', '1');

                            this.$store.commit('user/parseUserData', res.data.user);
                            this.$store.dispatch('flow/didCompleteSignIn');
                            this.$store.commit('variant/setVariant', res.data.variant || {});

                            // update body tag ab test classes
                            removeVariantClassesFromBody();
                            addVariantClassesToBody(res.data.variant);

                            //MVPD DMA Dispute Service Blocking for Existing Users: Not Allowing Sign In
                            if (
                                res.data?.user?.mvpdDispute?.isDmaInDispute &&
                                res.data?.user?.mvpdDispute?.mvpdProvider
                            ) {
                                this.$store.dispatch('modal/setModal', { modalName: 'DMADispute' });
                                return;
                            }

                            if (Domain.isInternational()) {
                                if (res.data.plans) {
                                    this.$store.dispatch('plan/setPlans', res.data.plans);
                                    if (this.multi_sub_plan_picker_enabled) {
                                        this.$store.dispatch(
                                            'plan/setAttributes',
                                            this.$root.serverData.multiSubPlanAttributes,
                                        );
                                    }
                                }
                            } else {
                                if (getMVPDAuthZObj()) {
                                    try {
                                        await this.bindMVPD();
                                    } catch (e) {
                                        return;
                                    }
                                }
                            }

                            this.trackLogin(ACTION_NAMES.LOGIN_SUCCESS);

                            if (
                                (Domain.isInternational() ||
                                    CBS.Registry.Features[AUTHSUITE_ENABLED]) &&
                                (this.fromConfirmation || this.mvpdEventBound)
                            ) {
                                if (res.data.user.isSubscriber) {
                                    window.location.href = HOME_PATH;
                                } else if (this.mvpdPartnerId === '') {
                                    this.$router.push('/partner');
                                } else {
                                    this.registerWithApi(this.authSuiteTokenErrorCallBack);
                                }
                            } else if (this.fromPartnerConfirmation || this.inPartnerFlow) {
                                if (
                                    res.data.user.isPartnerSubscription ||
                                    res.data.user.isSubscriber
                                ) {
                                    this.showPartnerError('ACTIVE_USER_PACKAGE_EXISTS');
                                } else {
                                    this.bindWithPartner().then((response) => {
                                        if (response?.success === false) {
                                            this.showPartnerError(
                                                response?.errorCode,
                                                'BINDING_ERROR',
                                            );
                                        }
                                    });
                                }
                            } else if (
                                this.$route.query.redirectUrl &&
                                this.$route.query.redirectUrl.includes('/account/direct/')
                            ) {
                                window.location.href = this.$route.query.redirectUrl;
                            } else {
                                context.successCallback(context);
                            }
                        } else {
                            context.buttonConfig.styleClass =
                                context.buttonConfig.styleClass.filter(
                                    (cssClass) => cssClass !== 'disabled',
                                );
                            context.formMessage = '';
                            context.formMessageError =
                                typeof res.message !== 'undefined' &&
                                res.message !== '' &&
                                res.message !== 'no error provided'
                                    ? res.message
                                    : this.$getLocale('login_error');
                            if (res.data.maxLoginAttempts) {
                                context.formMessageError = this.$getLocale(
                                    'you_have_been_locked_out_due_to_too_many_login_attempts_please_try_again_in_5_minutes',
                                );
                            }
                            if (typeof context.$options.propsData.errorCallback !== 'undefined') {
                                context.$options.propsData.errorCallback();
                            }

                            this.trackLoginFailure({
                                passIsVisible: this.passIsVisible.toString(),
                                loginFailureReason: context.formMessageError,
                                appLogText: res.data.message || 'No message provided',
                                appLogType: 'Sign In Error',
                                appLogCode: res.data.errorCode || 'No code provided',
                            });
                        }
                    })
                    .catch(() => {
                        context.buttonConfig.styleClass = context.buttonConfig.styleClass.filter(
                            (cssClass) => cssClass !== 'disabled',
                        );
                        context.formMessage = '';
                        context.formMessageError = context.$getLocale('login_error');
                    });
            },

            textInputErrorCSS: (key) => {
                return 'text-input-error qt-err-' + key;
            },

            trackFormSubmit() {
                try {
                    let payload = {
                        activationShortCode: this.activationCode,
                        clickLabel: this.$getLocale('sign_in_button'),
                        ottActivationPartner: this.$store.getters['flow/ottActivationPartner'],
                        pageType: this.trackingPageTypeLoginSubmit,
                        passIsVisible: this.passIsVisible.toString(),
                        userLoginType: this.$store.getters['flow/userLoginType'],
                    };

                    if (
                        this.$store.getters['flow/isRendezvous'] &&
                        this.$root.serverData.enhancedTrackActivationSuccess
                    ) {
                        payload.ctaText = this.buttonConfig.displayText;
                        payload.userLoginType = 'rendezvous';
                        payload.activationDeviceFamily =
                            this.$store.state.serverData.flow.partner || '';
                    }
                } catch (e) {}
            },

            trackLogin(action) {
                try {
                    tracking.persistState(this.$store.state.user.tags);
                    let activationDeviceFamily = this.$store.getters['flow/isRendezvous']
                        ? this.$store.state.serverData.flow.partner
                        : '';

                    let trackingParams = Object.assign(
                        {
                            activationDeviceFamily,
                            activationShortCode: this.activationCode,
                            ottActivationPartner: this.$store.getters['flow/ottActivationPartner'],
                            ctaText: this.buttonConfig.displayText,
                            pageType: this.trackingPageTypeLoginSuccess,
                            passIsVisible: this.passIsVisible.toString(),
                            userEventLoginSuccess: '1',
                            userLoginType: this.$store.getters['flow/isRendezvous']
                                ? RENDEZVOUS
                                : this.$store.getters['flow/userLoginType'],
                        },
                        this.trackingPayload,
                    );

                    tracking.trackAction(action, trackingParams);
                } catch (e) {}
            },

            trackLoginFailure({
                userEventLoginSuccess = '0',
                userLoginType = 'standard',
                passIsVisible = '',
                loginFailureReason = '',
                appLogText,
                appLogType,
                appLogCode,
            }) {
                const trackingParams = {
                    userEventLoginSuccess,
                    userLoginType,
                    passIsVisible,
                    loginFailureReason,
                    appLogText,
                    appLogType,
                    appLogCode,
                };

                tracking.trackAction(ACTION_NAMES.APP_LOG, trackingParams);
            },

            async __afterDispatch() {
                if (this.$store.getters['user/isMVPD']) {
                    if (!this.backendAuthsuiteIntegration) {
                        const isAuthorized = await this.checkUserAuthorizationStatus();
                        this.trackAuthSuiteSuccess();
                        if (isAuthorized) {
                            this.__sub();
                        } else {
                            this.attemptedUserRefresh = true;
                            await this.refreshAuthSuiteAccess(
                                this.authSuiteRefreshSuccessCallBack,
                                this.authSuiteTokenErrorCallBack,
                            );

                            this.__sub();
                        }
                    } else {
                        this.__sub();
                    }
                } else {
                    let featurePurchase =
                        this.$store.getters.featureIsActive(AA_PURCHASE) ||
                        Domain.isInternational();

                    if (featurePurchase || Domain.isInternational()) {
                        if (!this.$store.getters['user/isSubscriber']) {
                            this.__nonSub();
                        } else {
                            this.__sub();
                        }
                    } else {
                        this.__subForSignInFlow();
                    }
                }
            },

            async deauthAndUnbind() {
                if (!this.backendAuthsuiteIntegration) {
                    await this.authorizationService.disconnectDevice();
                    unbindWithAuthSuite();
                    deauthorize();
                }
                this.emitAuthError();
            },
            async checkUserAuthorizationStatus() {
                const { accessMethodsInUse } = this.accessStatus;
                if (accessMethodsInUse.length == 0) return false;
                let isAuthorized;
                try {
                    isAuthorized = await this.accessService.checkIsAuthorized('paramountplus');
                } catch (e) {
                    if (!e?.errorCode) {
                        const userRegistrationCountry =
                            this.$store.state.user.userRegistrationCountry;
                        const currentUrl = window.location.pathname;
                        const storedAccessToken = parseAuthsuiteAccessToken();
                        const storedDeviceId = storedAccessToken
                            ? storedAccessToken.deviceId
                            : null;
                        tracking.trackAction(ACTION_NAMES.APP_LOG, {
                            appLogText: `${JSON.stringify(e).substring(0, 75)} - deviceId: ${storedDeviceId}`,
                            appLogType: APP_LOG_TYPE.AUTHSUITE_SIGNIN_ERROR,
                            appLogCode: `Avoided unbinding ${userRegistrationCountry} user in ${this.authSuiteRegion} on ${currentUrl}`,
                        });
                        return true;
                    } else {
                        const deviceId = this.accessStatus?.deviceId;
                        tracking.trackAction(ACTION_NAMES.APP_LOG, {
                            appLogText: `error: ${e.errorCode} - deviceId: ${deviceId}`,
                            appLogType: APP_LOG_TYPE.AUTHSUITE_SIGNIN_ERROR,
                            appLogCode: `Unbinding ${userRegistrationCountry} user in ${this.authSuiteRegion} on ${currentUrl}`,
                        });
                        return false;
                    }
                }
                return isAuthorized;
            },

            // non subscriber sign in redirects

            async __nonSub() {
                if (
                    (this.flowType === 'f-upsell' && this.flowAction === 'login') ||
                    this.flowType === 'f-signin'
                ) {
                    this.__nonSubFirstStepForAuth();
                } else if (this.flowType === 'f-gift') {
                    this.__nonSubForGiftFlow();
                } else {
                    this.__nonSubDefault();
                }
            },

            __nonSubFirstStepForAuth() {
                const destinationURL = this.$store.getters['flow/origin_url'] || '';

                if (
                    destinationURL.match(/^\/account/) &&
                    !this.user.isRegistered &&
                    !this.user.isExSubscriber
                ) {
                    window.location.href = this.getNoCacheRedirectLink(destinationURL);
                } else if (Domain.isInternational()) {
                    this.__nonSubRoute(this.$store.getters['flow/getFirstStepForAuth'].path);
                } else {
                    this.$store.commit('flow/decrementStepsCount');
                    this.$router.push({
                        path: this.$store.getters['flow/getFirstStepForAuth'].path,
                    });
                }
            },

            __nonSubForGiftFlow() {
                if (this.$store.state.payment.giftToken) {
                    this.$router.push({
                        name: 'PLAN',
                    });
                } else {
                    this.$router.push({ name: 'GIFT' });
                }
            },

            __nonSubDefault() {
                if (Domain.isInternational()) {
                    this.__nonSubRoute(this.$store.getters['flow/getFirstStepForAuth'].path);
                } else {
                    this.$store.commit('flow/decrementStepsCount');
                    const paymentRoute = this.$store.getters['payment/paymentRoute'];
                    // START CBSENTGRTH-1380 : Explainer Step Optimizations
                    if (
                        Domain.isDomestic() ||
                        this.$store.getters['variant/explainerStepOptimizationsCondensedVariant']
                    ) {
                        this.$router.push({
                            name: paymentRoute,
                        });
                    } else {
                        this.$router.push({
                            name: paymentRoute,
                        });
                    }
                }
            },

            // subscriber sign in redirects

            __sub() {
                if (this.flowType === 'f-signin') {
                    this.__subForSignInFlow();
                } else if (this.flowType === 'f-gift') {
                    this.__subForGiftFlow();
                } else if (this.$store.getters['flow/destinationUrl']) {
                    this.__subDestinationUrl();
                } else {
                    this.__subDefault();
                }
            },

            __subForSignInFlow() {
                let destinationURL = this.$store.getters['flow/origin_url'] || '/';
                // Handles case of reset password -> signin -> destination
                if (Domain.isDomestic() && destinationURL.indexOf('resetpassword') > -1) {
                    destinationURL = `${CBS.Registry.region.prefix}${ACCOUNT_URL}`;
                }
                window.location.href = this.getNoCacheRedirectLink(destinationURL);
            },

            __subForGiftFlow() {
                if (Domain.isDomestic) {
                    this.$router.push({ name: 'GIFT_FUNNEL' });
                } else {
                    this.__subForGiftFlowIntl();
                }
            },

            __subForGiftFlowIntl() {
                // if user is subscriber that just signed in, apply token and send to account with  notification
                let giftToken = this.$store.state.payment.giftToken;

                if (giftToken) {
                    this.__subForGiftFlowWithToken();
                } else {
                    this.__subForGiftFlowWithoutToken();
                }
            },

            __subForGiftFlowWithToken() {
                let httpData = {
                    token: this.$store.state.payment.giftToken,
                    tk_trp: this.$store.state.token,
                };
                this.$store
                    .dispatch('payment/processGiftToken', httpData)
                    .then(({ data }) => {
                        window.location.href = GIFT_PURCHASE_ADD_URL;
                    })
                    .catch((err) => {
                        window.location.assign(this.$store.getters['flow/destinationUrl']);
                    });
            },

            __subForGiftFlowWithoutToken() {
                this.$router.push({ name: 'GIFT' });
            },

            __subDestinationUrl() {
                window.location.assign(this.$store.getters['flow/destinationUrl']);
            },

            __subDefault() {
                window.location.assign('/');
            },

            __nonSubRoute(url) {
                // Force regional redirect when user region was not available on load
                let regionBaseUrl = this.$root.serverData.regionBaseUrl;
                const region = regionBaseUrl.substr(1);
                const userRegistrationCountry = this.$store.state.user.userRegistrationCountry;
                const isUserRegionOnSunset = this.$store.state.user.isUserRegionOnSunset;

                if (userRegistrationCountry && userRegistrationCountry.toLowerCase() !== region) {
                    // force reload
                    let redirectUrl =
                        '/' + url.replace(regionBaseUrl, userRegistrationCountry.toLowerCase());
                    window.location.href = redirectUrl;
                } else {
                    if (isUserRegionOnSunset) {
                        window.location.href = regionBaseUrl;
                    } else {
                        // Update the promo serverData for ex subs
                        this.$store.dispatch('getPromo').catch(() => {});

                        this.$router.push({
                            path: url,
                        });
                    }
                }
            },

            // CBSCOMSUB-4696
            fireNotification() {
                let nbm = new NotificationBarModel({
                    message: this.pageAttributes.notification_html,
                    autoClose: false,
                    denyClose: true,
                });
                if (Domain.isDomestic()) this.openNotification(nbm);
            },
            async askForLocationPermission() {
                const dmaService = new DMAService();
                const dmaInfo = await dmaService.getDMA(true); // force fallback initially here on login
            },

            getNoCacheRedirectLink(destinationUrl) {
                const url = new URL(destinationUrl, window.location.origin);
                let searchParams = new URLSearchParams(url.searchParams);
                searchParams.set('nocache', new Date().getTime());
                url.search = '';

                return url.toString() + '?' + searchParams;
            },

            trackAuthSuiteSuccess() {
                let trackingActionName = ACTION_NAMES.LOGIN_SUCCESS;
                tracking.trackAction(trackingActionName, {
                    userRegId: this.user.regID,
                    userRegType: USER_REG_TYPE.MVPD,
                    authPartnerId: this.mvpdPartnerName,
                    userLoginType: USER_REG_TYPE.MVPD,
                    userEventLoginSuccess: '1',
                    vendorCode: this.getPackageInfo.vendor_code,
                    packageSource: this.user?.tags?.packageSource,
                    packageStatus: this.user?.tags?.packageStatus,
                });
            },
        },

        computed: {
            ...mapGetters({
                featureFlagIsActive: ['featureFlags/isActive'],
                isMvpd: ['user/isMVPD'],
            }),
            multi_sub_plan_picker_enabled() {
                return this.featureFlagIsActive('multi_sub_plan_picker_enabled');
            },
            isProviderEnabled() {
                return this.$root.serverData.mvpdEnabled
                    ? this.$root.serverData.mvpdEnabled
                    : false;
            },
            isPartner() {
                // TODO: Check if any partner flow is in use
                return PartnerService.isPartnerFlow(this.partner.partnerCSPValue);
            },
            skyUserPrompt() {
                return this.$getLocale('sky_activation_prompt', {
                    url: this.partner.partnerAuthAttributes?.csp_auth_display_url,
                });
            },
            showProviderSignin() {
                return this.$store.getters.featureIsActive(WEB_SIGN_IN_MVPD_ENABLED);
            },
            displaySignUpNonNative() {
                return (
                    !this.isInternational &&
                    !this.isLoggedIn &&
                    !this.isSignInPage &&
                    this.$store.getters['flow/isRendezvous'] &&
                    !this.$store.getters['flow/isNative']
                );
            },

            displayMVPDConnectCTA() {
                return this.showMVPDCtaDomestic || this.showMVPDCtaIntl;
            },

            showMVPDCtaIntl() {
                return (
                    this.isInternational &&
                    this.isProviderEnabled &&
                    !this.fromConfirmation &&
                    !this.isPartner
                );
            },

            showMVPDCtaDomestic() {
                let domesticConditions =
                    this.isLoggedIn ||
                    this.isDomesticPartner ||
                    this.$store.getters['flow/isRendezvous'] ||
                    this.isAarpFlow();

                return this.isDomestic && this.showProviderSignin && !domesticConditions;
            },

            signUpCTACopy() {
                return CBS.Registry.brand.name === 'PPLUS' ? 'Sign Up' : 'Create Account';
            },

            notSubscriberSignUpCopy() {
                return 'Not already a subscriber? Sign up here.';
            },

            hasCoupon() {
                return !!this.$store.state.promo;
            },

            isDomesticPartner() {
                return Object.values(PARTNERS).includes(this.$store.getters['flow/type']);
            },

            isInError() {
                return (key) => {
                    if (this.errors.length === 0) {
                        return false;
                    }
                    const result = this.errors.filter((err) => typeof err[key] !== 'undefined');
                    return result.length !== 0;
                };
            },
            formConfig() {
                return SignInConfig;
            },

            disableFormSubmit() {
                let { email, password } = this.form;

                // required fields filled
                return (
                    !email ||
                    !email.value ||
                    email.value === '' ||
                    !password ||
                    !password.value ||
                    password.value === ''
                );
            },

            featureAdditionalLogin() {
                return CBS.Registry.Features[AA_ADDITIONAL_LOGIN];
            },

            ...mapState(['partner']),
            ...mapState('pageAttributes', {
                pageAttributes: (state) => state.attributes,
            }),

            // TODO this section needs to be added only with AuthSuite
            ...mapState({
                accessStatus: (state) => state['authSuite']?.accessStatus,
                authSuiteTokens: (state) => state['authSuite']?.tokens,
                tokenStatus: (state) => state['authSuite']?.tokenStatus,
                accessService: (state) => state['authSuite']?.authSuiteServices.accessService,
                loginEvent: (state) => state['authSuite']?.authSuiteServices.loginEvent,
                authorizationService: (state) =>
                    state['authSuite']?.authSuiteServices.authorizationService,
                authSuiteRegion: (state) => state['authSuite']?.authSuiteRegion,
                mvpdPartnerId: (state) => state['authSuite']?.selectedProviderCode,
                mvpdPartnerName: (state) => state['authSuite']?.selectedProviderName,
                mvpdEventBound: (state) => state['authSuite']?.mvpdLoginEventBound,
            }),

            ...mapState('flow', {
                activationCode: (state) => {
                    return state.config.code;
                },
                flowType: (state) => {
                    return state.config.type;
                },
                flowAction: (state) => {
                    return state.config.action;
                },
                userConsent: (state) => {
                    return state.userConsent;
                },
            }),
            // CBSCOMSUB-4696
            notification_html() {
                return this.pageAttributes.notification_html;
            },

            subHeading() {
                if (this.$store.getters['flow/isEdu']) {
                    return this.$store.getters['user/eduProfile']
                        ? this.pageAttributes.edu_disclaimer
                        : false;
                }

                return this.pageAttributes.aa_sign_in_sub_heading;
            },

            trackingPageTypeLoginSubmit() {
                const customSignInPageTypes = {
                    [FLOWTYPE_SHO_MIGRATED]: PAGE_TYPES.SIGNIN_SHO_MIGRATED,
                };

                return customSignInPageTypes[this.flowType] || PAGE_TYPES.SIGNIN;
            },

            trackingPageTypeLoginSuccess() {
                const customSignInPageTypes = {
                    [FLOW_TYPE.GIFT]: 'svod_signin',
                    [FLOWTYPE_SHO_MIGRATED]: PAGE_TYPES.SIGNIN_SHO_MIGRATED,
                };

                return customSignInPageTypes[this.flowType] || 'login';
            },

            displayForgotPassLink() {
                return !this.hideForgotPassLink;
            },
            getPackageInfo() {
                return this?.user?.svod?.user_package;
            },
        },
        filters: {
            valuesFromObj: (objStr) => {
                return Object.values(objStr).toString();
            },
        },
        components: {
            UserAcknowledgement,
            BannerEl,
            SectionRule,
            StepsHeader,
            ButtonCta,
            InputElm,
            LabelInput,
            BackToRendezvousOptions,
            // CBSCOMSUB-4696
            NotificationBar,
        },

        // Lifecycle Hooks
        beforeCreate: function () {
            Object.assign(SignInConfig.hiddenInputList.tk_trp, {
                value: this.$store.state.token,
            });
        },

        created() {
            this.prePopulateFields();
        },

        mounted() {
            this.addCaptchaValidation(); // CBSCOMSUB-4696
            if (this.notification_html) {
                this.fireNotification();
            }

            if (Domain.isDomestic()) {
                this.askForLocationPermission();
            }
        },
        updated() {
            const button = document.querySelector('.button__close');
            if (button) {
                button.focus();
            }
        },

        destroyed() {
            Object.assign(SignInConfig.inputList.email, {
                value: '',
            });
        },

        watch: {
            // Tokens have been refreshed, if from confirmation attempt to register again with new tokens.
            // or we attempted to refresh authsuite access and we are not from confirmation, attempt to refresh again because we recieved a new set of tokens
            authSuiteTokens(newValue, oldValue) {
                if (this.fromConfirmation && !this.formIsValid) {
                    if (this.tokenStatus === 'valid' && !isEqual(newValue, oldValue)) {
                        this.registerWithApi(this.authSuiteTokenErrorCallBack);
                    }
                } else if (
                    this.tokenStatus === 'valid' &&
                    !this.userAuthSuiteAccountRefreshed &&
                    this.attemptedUserRefresh &&
                    !isEqual(newValue, oldValue)
                ) {
                    this.refreshAuthSuiteAccess(
                        this.authSuiteRefreshSuccessCallBack,
                        this.authSuiteTokenErrorCallBack,
                    );
                }
            },
            // CBSCOMSUB-4696
            notification_html: function (n, o) {
                this.fireNotification();
            },
            async accessStatus(newValue, oldValue) {
                // If account has been refreshed
                // check if user is authorized, if not then disconnect, deauth, and unbind
                if (this.userAuthSuiteAccountRefreshed) {
                    if (this.isProviderEnabled) {
                        const isAuthorized = await this.checkUserAuthorizationStatus();
                        if (isAuthorized) {
                            this.__sub();
                        } else {
                            this.deauthAndUnbind();
                        }
                    }
                }
            },
        },
    };
</script>
