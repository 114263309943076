<template>
    <div>
        <!-- price -->
        <div v-if="dspPlanPrice()" :class="getPriceClassList()" v-html="getPlanPrice()"></div>

        <!-- new price -->
        <div v-if="dspNewPrice()" class="value-prop-price" v-html="getNewPrice()"></div>

        <!-- that's like -->
        <div v-if="dspSavings()" class="value-prop-savings">
            {{ getSavings() }}
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import { getLocale } from 'services/LocalizationService';

    import { camelCase } from 'lodash';
    import { COUPON_TYPES } from 'aa/vue/models/CouponDiscount';
    import Plan from 'aa/vue/models/Plan';
    import { capitalize } from 'aa/vue/components/account/helpers';
    import { sprintf } from 'sprintf-js';

    export default {
        name: 'ValuePropStatePricing',
        components: {},
        props: {
            plan: {
                type: Plan,
                required: true,
            },
        },
        methods: {
            // template methods
            getPriceClassList() {
                return {
                    'value-prop-price': true,
                    'value-prop-strike': this.useStrike(),
                };
            },
            dspPlanPrice() {
                return !this.hasPlanCoupon() || this.useStrike();
            },
            getPlanPrice() {
                // $0.00/mo after trial OR $0.00/yr after trial
                // $0.00/mo OR $0.00/yr
                // --strike-- $0.00 --strike--
                return this.useStrike()
                    ? `${this.plan.getRetailPrice()}`
                    : `${this.plan.getRetailPrice()}<span>/${this.getPlanCadenceAbbr()} ${this.getAfterTrial()}</span>`;
            },
            useStrike() {
                return this.hasPlanCoupon() && this.couponIsFixedOrPercent();
            },
            dspNewPrice() {
                return this.hasPlanCoupon();
            },
            getForeverFormat(unit) {
                let format;
                if (this.plan.hasForeverCoupon() && this.isExSubscriber) {
                    format = `%s<span>/${unit}</span>`;
                } else if (this.plan.hasForeverCoupon()) {
                    format = `%s<span>/${unit} after trial</span>`;
                }

                return format;
            },
            getForeverCouponString() {
                const rate = this.plan.dollarFormat(this.plan.getAdjustedPromotionalRate());
                if (rate) {
                    const cadence = this.getPlanCadenceAbbr();
                    const format = this.getForeverFormat(cadence);
                    return this.plan.isMonthly
                        ? sprintf(format, rate, cadence)
                        : sprintf(format, rate);
                }
                return '';
            },
            getNewPrice() {
                try {
                    if (this.plan.hasForeverCoupon()) {
                        return this.getForeverCouponString();
                    }
                    // $0.00 for X months
                    // $0.00 for first year
                    // $0.00 for 2 years

                    // base values
                    let format;
                    let amount = this.plan.getCouponAmount();
                    // let unit = this.plan.getCouponUnit()
                    let unitAmount = this.plan.getCouponStringUnitAmount();
                    let unitWord = this.plan.getCouponStringUnit();
                    let unitAbbr = this.getPlanCadenceAbbr();

                    switch (this.plan.getCouponType()) {
                        case COUPON_TYPES.FREE_TRIAL:
                            amount = this.plan.dollarFormat(this.plan.rawPrice);
                            unitAmount = '';

                            // '[amount]/[mo] <after trial>'
                            // '[amount]/[yr] <after trial>'
                            format = `%s<span>/%s ${this.getAfterTrial()}</span>`;
                            return sprintf(format, amount, unitAbbr);

                        case COUPON_TYPES.FIXED:
                        case COUPON_TYPES.PERCENT:
                            amount = this.plan.dollarFormat(this.plan.getAdjustedPromotionalRate());
                            format = this.getFormat();

                            // replace "1" with "first" for annual plans using 1-year coupon
                            unitAmount =
                                this.plan.isAnnual && unitAmount === 1 && unitWord === 'year'
                                    ? 'first'
                                    : unitAmount;

                            // pluralize
                            unitWord = unitAmount > 1 ? unitWord + 's' : unitWord;

                            return this.plan.isMonthly
                                ? // [amount]/[unitAbbr] for [unitAmount] [unitWord]
                                  sprintf(format, amount, unitAbbr, unitAmount, unitWord)
                                : // [amount] for [unitAmount] [unitWord]
                                  sprintf(format, amount, unitAmount, unitWord);
                            break;
                        default:
                            break;
                    }
                } catch (e) {
                    return '';
                }
            },
            getFormat() {
                let format;
                if (this.plan.isMonthly) {
                    format = '%s<span>/%s for %s %s</span>'; // [amount]/[unitAbbr] for [unitAmount] [unitWord](s)
                }

                if (this.plan.isAnnual) {
                    format = '%s<span> for %s %s</span>'; // [amount] for [unitAmount] [unitAbbr]
                    if (this.plan.getCouponStringUnitAmount() > 1) {
                        format = `%s<span>/${this.getPlanCadenceAbbr()} for %s %s</span>`; // [amount]/yr for [unitAmount] [unitAbbr]
                    }
                }
                return format;
            },
            dspSavings() {
                return this.plan.isAnnual;
            },
            getSavings() {
                // that's like $0.00/mo
                let monthlyPrice = this.plan.rawPrice / 12;

                if (this.hasPlanCoupon()) {
                    if (this.plan.getCouponType() === COUPON_TYPES.FREE_TRIAL) {
                        monthlyPrice = this.plan.rawPrice;
                    } else {
                        monthlyPrice = this.plan.getAdjustedPromotionalRate();
                    }
                    monthlyPrice = monthlyPrice / 12;
                }

                monthlyPrice = this.plan.dollarFormat(monthlyPrice);

                return `${getLocale('thats_like', {}, "That's like")} ${monthlyPrice}/${this.getMonthAbbr()}`;
            },

            // helpers
            hasPlanCoupon() {
                return this.plan.hasValidCoupon();
            },
            getPlanCadenceAbbr() {
                return this.plan.isMonthly ? this.getMonthAbbr() : this.getYearAbbr();
            },
            getMonthAbbr() {
                return getLocale('month_abbr', {}, 'mo');
            },
            getYearAbbr() {
                return getLocale('year_abbr', {}, 'yr');
            },
            couponIsFixedOrPercent() {
                return (
                    this.plan.getCouponType() === COUPON_TYPES.FIXED ||
                    this.plan.getCouponType() === COUPON_TYPES.PERCENT
                );
            },
            getAfterTrial() {
                // only show "after trial" when there is no coupon
                // OR there is an EFT coupon
                if (!this.plan.trial) {
                    return '';
                }
                if (this.isExSubscriber && !this.hasPlanCoupon()) {
                    return '';
                }
                if (!this.hasPlanCoupon() || this.isExtendedFreeTrialCoupon()) {
                    return getLocale('after_trial', {}, 'after trial');
                } else {
                    return '';
                }
            },
            isExtendedFreeTrialCoupon() {
                return this.plan.getCouponType() === COUPON_TYPES.FREE_TRIAL;
            },
        },
        computed: {
            ...mapState('user', ['isExSubscriber']),
        },
    };
</script>
