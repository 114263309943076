<template>
    <div :class="getParentClass()">
        <div class="value-prop-flex-item">
            <div v-if="hasCheckMark()" class="checkmark" />

            <div v-else-if="hasDashIcon()" class="dash" />

            <!-- text (ex: limited ads) -->
            <div v-else-if="showText()">
                {{ getValuePropStateText() }}
            </div>

            <!-- cell details with pricing/coupon info -->
            <ValuePropStatePricing v-else :plan="plan" />
        </div>
    </div>
</template>
<script>
    import { LAST } from 'aa/vue/constants/shared';
    import { mapGetters, mapState } from 'vuex';
    import Product from 'aa/vue/models/Product';
    import Plan from 'aa/vue/models/Plan';
    import ValuePropStatePricing from 'aa/vue/components/upsell/ValuePropStatePricing.vue';

    export default {
        name: 'ValuePropState',
        components: { ValuePropStatePricing },
        data: function () {
            return {
                defaultText: '_text',
                defaultCheckbox: '_checkbox',
            };
        },
        props: {
            plan: {
                type: Plan,
                required: true,
            },
            valueProp: {
                type: Object,
                required: true,
            },
        },
        computed: {
            ...mapState('user', ['isExSubscriber']),
            ...mapGetters({
                upsellPlansAnnualEnabled: 'featureFlags/upsellPlansAnnualEnabled',
            }),
            getValueProp() {
                if (this.isPriceState()) {
                    if (this.plan.isLCP) {
                        this.valueProp.essential_text = this.getPlanPrice;
                    } else {
                        this.valueProp.premium_text = this.getPlanPrice;
                    }
                }
                return this.valueProp;
            },
            getPlanPrice() {
                return this.plan.getRetailPrice();
            },
        },
        methods: {
            showText() {
                return !this.isPriceState() && this.getValuePropStateText().length > 1;
            },
            getParentClass() {
                return {
                    'upsell-plans-grid-item': true,
                    'plan-state': true,
                    'coupon-state': this.hasPlanCoupon(),
                    'price-state': this.isPriceState() && !this.hasPlanCoupon(),
                    hasAnnual: this.upsellPlansAnnualEnabled,
                };
            },
            textOnly: function () {
                return this.valueProp[this.getTextKey()] !== '';
            },
            hasCheckMark: function () {
                return (
                    this.getValueProp[this.getCheckBoxKey()] &&
                    !this.getValueProp[this.getTextKey()]
                );
            },
            hasDashIcon: function () {
                return (
                    !this.isPriceState() &&
                    !this.getValueProp[this.getCheckBoxKey()] &&
                    !this.getValueProp[this.getTextKey()]
                );
            },
            getCheckBoxKey: function () {
                return this.getPlanTypeValuePropPrefix() + this.defaultCheckbox;
            },
            getTextKey: function () {
                return this.getPlanTypeValuePropPrefix() + this.defaultText;
            },
            getPlanTypeValuePropPrefix() {
                return this.plan.isLCP ? Product.TIER_ESSENTIAL : Product.TIER_PREMIUM;
            },
            getValuePropStateText() {
                return this.getValueProp[this.getTextKey()];
            },
            hasPlanCoupon() {
                if (this.isPriceState()) {
                    return this.plan.hasValidCoupon();
                }
                return false;
            },
            isPriceState() {
                return this.valueProp?.position === LAST;
            },
        },
    };
</script>
