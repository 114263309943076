<!-- START TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy -->
<template>
    <div class="annual-toggle-green-savings-pill">
        <SwitchToggle :config="toggleConfig" @toggle="toggle"></SwitchToggle>
        <PillComponent className="save-message" textValue="Save 23%" />
    </div>
</template>

<script>
    import SwitchToggle from 'atoms/SwitchToggle';
    import { mapState } from 'vuex';
    import Product from 'aa/vue/models/Product';
    import PillComponent from 'aa/vue/components/PillComponent';

    export default {
        name: 'AnnualToggleCenterAlignedGreenSavingsPill',
        components: {
            SwitchToggle,
            PillComponent,
        },
        props: {
            // default active item in toggle
            defaultActive: {
                type: String,
                required: true,
                default: '',
            },
            toggleLeftLabel: {
                type: String,
                required: true,
                default: '',
            },
            toggleRightLabel: {
                type: String,
                required: true,
                default: '',
            },
        },
        data: function () {
            return {
                activeSelected: this.defaultActive,
            };
        },
        computed: {
            ...mapState('pageAttributes', {
                pageAttr: (state) => state.attributes,
            }),
            toggleConfig() {
                return {
                    id: 'toggle',
                    value: this.getPlanType !== Product.TYPE_MONTHLY,
                    displayTexts: {
                        Off: 'PAY ANNUALLY',
                        On: 'PAY ANNUALLY',
                    },
                };
            },
            getPlanType() {
                return this.$store.getters['plan/getCurrentPlan']?.planType ?? '';
            },
        },
        methods: {
            toggle(action) {
                if (this.activeSelected === this.toggleLeftLabel) {
                    this.activeSelected = this.toggleRightLabel;
                } else {
                    this.activeSelected = this.toggleLeftLabel;
                }

                this.$emit('change', {
                    // returns current active item
                    value: this.activeSelected,
                });
            },
        },
    };
</script>
<!-- END TEST PLOCTOPLUS-2947 Annual Plan Emphasis on PAP Adjusted Pricing Display and Hierarchy -->
