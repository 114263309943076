<template>
    <div :class="getClassList">
        <div class="grid-item hasAnnual" v-if="upsellPlansAnnualEnabled">
            <div class="grid-item-interior">
                <div class="plan-name">{{ $getLocale('plans') }}</div>
            </div>
        </div>
        <UpsellPlansHeaderItem
            v-for="plan in plans"
            :key="plan.code"
            :plan="plan"
            @hasCoupon="handleHasCoupon" />
    </div>
</template>

<script>
    import UpsellPlansHeaderItem from 'aa/vue/components/upsell/UpsellPlansHeaderListItem';
    import { mapGetters } from 'vuex';

    export default {
        name: 'UpsellPlansHeaderList',
        data: function () {
            return {
                hasCoupon: false,
            };
        },
        props: {
            plans: {
                type: Array,
                required: true,
            },
            toggled: {
                type: Number,
                required: true,
            },
        },
        mounted: function () {},
        methods: {
            handleHasCoupon(value) {
                this.hasCoupon = value;
            },
        },
        computed: {
            ...mapGetters({
                upsellPlansAnnualEnabled: 'featureFlags/upsellPlansAnnualEnabled',
            }),
            getClassList() {
                return {
                    'plan-header-grid': true,
                    'has-coupon': this.hasCoupon,
                };
            },
        },
        components: {
            UpsellPlansHeaderItem,
        },
        watch: {
            toggled: function (n, o) {
                this.hasCoupon = false;
            },
        },
    };
</script>
