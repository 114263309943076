<template>
    <div v-if="show" class="grid-item text-center rendezvous-back-btn-container">
        <SectionRule v-if="separator" :dividerCopy="$getLocale('or')"></SectionRule>
        <a v-on:click="clickCallback" class="rendezvous-back-link" :href="rendezvousSelectUrl">
            {{ $getLocale('back_to_rendezvous_options') }}
        </a>
    </div>
</template>

<script>
    import SectionRule from 'atoms/SectionRule';
    import get from 'lodash/get';
    import { FLOW_TYPE } from 'models/FlowModel';

    export default {
        name: 'BackToRendezvousOptions',
        components: {
            SectionRule,
        },
        props: {
            separator: {
                type: Boolean,
            },
        },
        data: function () {
            return {
                rendezvousSelectUrl: '',
            };
        },
        methods: {
            backToOptionsRouteTrack(e) {
                //TODO: implement rendezvous tracking
            },

            clickCallback(e) {
                this.backToOptionsRouteTrack(e);
            },
        },
        created() {
            if (
                this.$store.getters['flow/isRendezvous'] &&
                this.$store.getters['flow/type'] !== FLOW_TYPE.AGNOSTIC_RENDEZVOUS
            ) {
                const prefix = get(CBS, 'Registry.region.prefix', '');

                this.show = true;
                this.rendezvousSelectUrl =
                    prefix + this.$store.getters['flow/afterSignUpUrl'] + 'select';
            } else {
                this.show = false;
            }
        },
    };
</script>
